export const API_VERSION = "v1.0";
export const SOCKET_URL = "ws://localhost:5000/messages";
export const COM_PORT = "COM_PORT";
export const IP_ADDRESS = "IP_ADDRESS";
export const PORT = "PORT";

export const ARENA_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6ImFlY2I4NDcxLWNkYWMtNGQ0Ny04ZmMwLTE1ODVkOTk1OTMzNSIsImlhdCI6MTY4MjQ5NDYwNX0.i9NRXav1uTVFAukgG6M5JbPL5ZutMNcSCaHt3owKeqI`;
export const SUPER_ADMIN_TOKEN = ``;
export const IMACULIX_TOKEN = ``;
export const TOKEN = ARENA_TOKEN;

const HOST_URL = `brijeurope-kiosk.brij.tech`;

const LIVEBASEURL = `https://brijeurope-api.brij.tech`;
const DEVBASEURL = ``;
// const DEVBASEURL = `https://cinematic-server-gdc-3.brij.tech`;
// const DEVBASEURL = `http://shringar.brij.tech:3950`;

export const BASEURL =
  window.location.host === HOST_URL ? LIVEBASEURL : DEVBASEURL;

//  mode should be an integer
// 1- only ticket
// 2- only fnb
// 3 - both

export const mode = 3;
